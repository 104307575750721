import "./style.css";
import { Icons } from "utils/iconPath";
import { useRef, useState } from "react";
import { useEffect } from "react";
import "animate.css";
const SplashScreen = () => {
  const [animationTrue, setAnimationTrue] = useState(true);
  const AnimationRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setAnimationTrue(false);
      AnimationRef?.current?.classList?.add("animate__fadeOut");
    }, 3000);
  }, [animationTrue]);

  return (
    <>
      {/* <Fade> */}
      <div
        className="splashScreenContainer animate__animated animate__fadeIn animate__fast"
        ref={AnimationRef}
      >
        <img src={Icons.APP_LOGO} alt="App Logo" className="SplashScreen" />
      </div>
      {/* </Fade> */}
    </>
  );
};

export default SplashScreen;
