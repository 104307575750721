import "./sideBar.css";
import { Icons } from "utils/iconPath";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import SideBarLinks from "../SideBarLinks";
import { useDispatch } from "react-redux";
import { closeDrawer } from "redux/Slice/drawerSlice";

import { useEffect, useRef, useState } from "react";
import LogoutModal from "./logoutModal";

const SideBar = ({ reference }) => {
  const tabGroupRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation("sideBar");

  // Variables
  let pageName = location?.pathname?.split("/");

  // state
  const [open, setOpen] = useState(false);

  const modalCloseHandle = () => {
    setOpen(!open);
  };

  const toggleDrawer = () => {
    dispatch(closeDrawer());
  };

  const handleTabScroll = (tabId) => {
    const tab = document?.getElementById(tabId);
    if (tab && tabGroupRef?.current) {
      tabGroupRef?.current.scrollTo({
        top: tab?.offsetTop - tabGroupRef?.current?.offsetTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };

    if (open) {
      document.body.classList.add("modal-open");
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  // Logic to get actual viewport Height of mobile and tablet mode (for credit section)
  // 1.) First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // 2.) Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    // 3.)  We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  return (
    <>
      <LogoutModal onClose={modalCloseHandle} isOpen={open} />
      <div className="SideBar" ref={reference}>
        <div className="logoContainer clickable">
          <Link to={"/"}>
            <img className="logo" src={Icons.SIDEBAR_APP_LOGO} alt="logo" />
          </Link>
        </div>

        <div className="panelNameContainer">
          <h1 className="panelName">{`supplier's ${t("panel")}`}</h1>
        </div>

        <ul className="tabsContainer" ref={tabGroupRef}>
          {SideBarLinks?.map((item, key) => {
            let link = item?.link?.split("/");

            return (
              <li key={key} onClick={toggleDrawer}>
                <Tab
                  link={item?.link}
                  icon={item?.icon}
                  label={t(item?.label)}
                  isActive={pageName[1] === link[1]}
                  id={item?.label}
                  onClick={() => {
                    handleTabScroll(item?.label);
                  }}
                />
              </li>
            );
          })}
        </ul>

        <div className="separator"></div>
        <Tab
          icon={Icons.LOGOUT}
          label={t("logout")}
          link={pageName[1]}
          onClick={modalCloseHandle}
        />
      </div>
    </>
  );
};
export default SideBar;

const Tab = ({ link, icon, label, onClick, isActive, id }) => {
  return (
    <Link to={link} onClick={onClick} id={id}>
      <div className={`Tab ${isActive && "Tab_active"} unselectable clickable`}>
        <div className="iconContainer">
          <img className="icon" src={icon} alt="tab icon" />
        </div>
        <p className="name">{label}</p>
      </div>
    </Link>
  );
};
