import "./style.css";

const Input = ({
  start,
  end,
  startChildren,
  endChildren,
  input,
  Style,
  onChange,
  onBlur,
  value,
  type,
  placeholder,
  max,
  maxLength,
  errorMessage,
  error,
  onKeyDown,
  label,
  labelStyle,
  inputClassName,
  classNameInputContainer,
  letterSpacing,
  style,
  onClick,
  readOnly,
  isRequired,
  InputStyle,
  disabled,
}) => {
  return (
    <>
      <div
        className={`InputContainer ${classNameInputContainer}`}
        style={{
          ...style,
          opacity: disabled ? 0.35 : 1,
          cursor: disabled ? "not-allowed" : "inherit",
        }}
        onClick={onClick}
      >
        {label && (
          <div className="labelContainer">
            <p className="label" style={labelStyle}>
              {label}
            </p>
            {!isRequired && <p className="bodyText optional">Optional</p>}
          </div>
        )}
        <div
          className="inputDiv"
          style={{
            border: error ? "0.5rem solid #ff4d4f" : "",
            ...Style,
          }}
          id="InputContainer"
        >
          {start ? <div className="inputStart">{startChildren}</div> : null}

          <input
            type={type ?? "text"}
            placeholder={placeholder}
            className={`inputElement ${inputClassName}`}
            value={value ?? ""}
            onChange={onChange}
            maxLength={maxLength}
            onKeyDown={onKeyDown}
            style={{
              letterSpacing: letterSpacing ? "0.5rem" : "0rem",
              ...InputStyle,
              cursor: disabled ? "not-allowed" : "inherit",
            }}
            onBlur={onBlur}
            readOnly={readOnly ?? false}
            disabled={disabled}
          />
        </div>

        {error ? <p className="bodyText errorMessage">{error}</p> : null}

        {end ? <div className="inputEnd">{endChildren}</div> : null}
      </div>
    </>
  );
};

export default Input;

export const TextArea = ({
  onChange,
  value,
  type,
  placeholder,
  maxLength,
  error,
  onKeyDown,
  label,
  inputClassName,
  onBlur,
  isRequired,
  rows,
  cols,
  style,
}) => {
  return (
    <>
      <div className="TextAreaDiv">
        {label && (
          <div className="labelContainer">
            <p className="label">{label}</p>
            {!isRequired && <p className="bodyText optional">Optional</p>}
          </div>
        )}
        <textarea
          type={type ?? "text"}
          placeholder={placeholder}
          className={`inputElementTextArea ${inputClassName}`}
          value={value ?? ""}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          style={{ border: error ? "0.5rem solid #ff4d4f" : "", ...style }}
          maxLength={maxLength}
          rows={rows}
          cols={cols}
        />
        {error ? <p className="bodyText errorMessage">{error}</p> : null}
      </div>
    </>
  );
};
