import Cookies from "js-cookie";
import { USER_TOKEN } from "./constant";
import {
  loginUser,
  logoutUser,
} from "redux/Slice/authenticationSlice/authenticationSlice";
import store from "redux/store";

//Get Error Message
export function getErrorMessage(data) {
  let returnError = [];
  if (typeof data === "string") return data;
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      let errorValue = "";
      for (const [, value] of Object.entries(data[i])) {
        errorValue += value + " ";
      }
      returnError.push(errorValue + "|  ");
      errorValue = "";
    }
    return returnError;
  } else {
    for (const [, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const element = value[i];
          return element;
        }
      } else return value;
    }
  }
}

// login user
export const makeUserLogin = (token) => {
  store.dispatch(loginUser());
  Cookies.set(USER_TOKEN, token);
  localStorage.setItem("token", token);
};

// logout user
export const makeUserLogout = () => {
  store.dispatch(logoutUser());
  Cookies.remove(USER_TOKEN);
  localStorage.clear();
  Cookies.remove();
};

export function saveUserData(data) {
  // ReduxStore.dispatch(saveUserAction(data));
}

// change language
// export const handleChangeLanguage = async (res) => {
//   ReduxStore.dispatch(languageAction(res));
//   await setLanguage(res);
// };

// get image size in kb
export const getSizeInKb = (value) => {
  let KB = value / 1024;
  return `${Math.floor(KB)}`;
};

// get image
export const getImageUrl = (path) => {
  if (path.includes("http")) {
    return path;
  } else {
    return process.env.BASE_URL + path;
  }
};

// set text length
export const setTextLength = (text, length) => {
  if (text.length < length) return text;
  else return text.slice(0, length).concat("...");
};

// number formate
export function numFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

// get all country code
// export const getAllCountryPhoneCode = () => {
//   let temp = [];
//   CountryCallingCode.forEach((ele) => {
//     temp.push({
//       name: `+${ele.countryCodes.toString()} ${ele.country}`,
//       code: `+${ele.countryCodes.toString()}`,
//     });
//   });
//   return temp;
// };

export function ConvertDMSToDD(degrees, minutes, seconds, direction) {
  var dd = degrees + minutes / 60 + seconds / 3600;

  if (direction === "S" || direction === "W") {
    dd = dd * -1;
  }
  return dd;
}

// make query params
export function getAllQueryParams(query) {
  let tempQuery = "";
  for (let key in query) {
    if (query[key] === "" || query[key] === null || query[key] === undefined) {
      delete query[key];
    } else {
      tempQuery = tempQuery + `${key}=${query[key]}&`;
    }
  }
  tempQuery = tempQuery.substring(0, tempQuery.length - 1);

  return tempQuery.length === 0 ? "" : `?${tempQuery}`;
}

export function getRemainingAttemps(attempts) {
  return 3 - attempts;
}

export function getFileNameFromUrl(file) {
  let lastIndex = file.lastIndexOf("?X-A");
  if (lastIndex === -1) {
    let ext = file.split("/").pop();
    return decodeURIComponent(ext);
  } else {
    let path = file.substring(0, lastIndex);
    let ext = path.split("/").pop();
    return decodeURIComponent(ext);
  }
}

export const registerServiceWorker = async () => {
  if (!("serviceWorker" in navigator)) {
    throw Error("Service Worker are not supported by this browser");
  }
  return await navigator.serviceWorker.register("./firebase-messaging-sw.js");
};

export const getReadyServiceWorker = async () => {
  if (!("serviceWorker" in navigator)) {
    throw Error("Service Worker are not supported by this browser");
  }

  return navigator.serviceWorker.ready;
};

export function isValidation(value, type) {
  const emailRegex =
    /^[-!#$%&'*+0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (type === "email") {
    if (value === "") {
      return {
        error: "Please enter email address",
        success: false,
      };
    } else if (!emailRegex?.test(value)) {
      return {
        error: "Please enter a valid email address",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }

  if (type === "phone") {
    if (value === "") {
      return {
        error: "Please enter phone number",
        success: false,
      };
    } else if (value?.length < 9) {
      return {
        error: "Please enter a valid phone number",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }

  if (type === "name") {
    if (value === "") {
      return {
        error: "Please enter name",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }

  if (type === "villaOrApartment") {
    if (value === "") {
      return {
        error: "Please enter villa or apartment details",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }

  if (type === "buildingOrStreet") {
    if (value === "") {
      return {
        error: "Please enter building or street details",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }

  if (type === "address") {
    if (value === "") {
      return {
        error: "Please enter address",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }
}
